const Google = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.02 12.2727C24.02 11.4218 23.9436 10.6036 23.8018 9.81812H12.5V14.4599H18.9582C18.68 15.9599 17.8345 17.2308 16.5636 18.0818V21.0927H20.4418C22.7109 19.0036 24.02 15.9272 24.02 12.2727Z'
        fill='#4285F4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5005 24C15.7405 24 18.4569 22.9255 20.4423 21.0928L16.5641 18.0818C15.4896 18.8018 14.115 19.2273 12.5005 19.2273C9.37504 19.2273 6.72958 17.1164 5.78595 14.28H1.77686V17.3891C3.7514 21.3109 7.80958 24 12.5005 24Z'
        fill='#34A853'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.78545 14.2799C5.54545 13.5599 5.40909 12.7908 5.40909 11.9999C5.40909 11.209 5.54545 10.4399 5.78545 9.71993V6.61084H1.77636C0.963636 8.23084 0.5 10.0636 0.5 11.9999C0.5 13.9363 0.963636 15.769 1.77636 17.389L5.78545 14.2799Z'
        fill='#FBBC05'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5005 4.77273C14.2623 4.77273 15.8441 5.37818 17.0878 6.56727L20.5296 3.12545C18.4514 1.18909 15.735 0 12.5005 0C7.80958 0 3.7514 2.68909 1.77686 6.61091L5.78595 9.72C6.72958 6.88364 9.37504 4.77273 12.5005 4.77273Z'
        fill='#EA4335'
      />
    </svg>
  );
};

export default Google;
