const Close = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3734 4.04042C17.764 3.6499 17.764 3.01673 17.3734 2.62621C16.9829 2.23568 16.3498 2.23568 15.9592 2.62621L9.99967 8.58577L4.04011 2.62621C3.64959 2.23568 3.01643 2.23568 2.6259 2.62621C2.23538 3.01673 2.23538 3.6499 2.6259 4.04042L8.58546 9.99998L2.6259 15.9595C2.23538 16.3501 2.23538 16.9832 2.6259 17.3738C3.01643 17.7643 3.64959 17.7643 4.04011 17.3738L9.99967 11.4142L15.9592 17.3738C16.3498 17.7643 16.9829 17.7643 17.3734 17.3738C17.764 16.9832 17.764 16.3501 17.3734 15.9595L11.4139 9.99998L17.3734 4.04042Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Close;
