import { motion } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import signUpStyles from '~/components/SignUpCard/SignUpCard.module.scss';
import Close from '~/icons/Close';
import Google from '~/icons/Google';

import { Form, NavLink, useLocation } from '@remix-run/react';

import styles from './LogInModal.module.scss';

interface Props {
  onClose: () => void;
}

const LogInModal: FC<Props> = ({ onClose }) => {
  const location = useLocation();
  const initialPath = useRef(location.pathname);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== initialPath.current) {
      onClose();
    }
  }, [location.pathname, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.logInModal}>
        <button
          onClick={onClose}
          className={styles.closeButton}
          aria-label='Close log in modal'
        >
          <Close />
        </button>
        <h5 className={styles.heading}>Log in to continue</h5>
        <div className={styles.description}>
          <p>Log in for unlimited access & full experience</p>
        </div>
        <Form method='post' action='/login'>
          <button
            type='submit'
            name='provider'
            value='google'
            className={signUpStyles.socialButton}
          >
            <Google />
            Continue with Google
          </button>
        </Form>
        <div className={styles.note}>
          <p>
            By continuing, you agree to the{' '}
            <NavLink to='/privacy-policy'>Privacy Policy.</NavLink>. You also
            agree to receive our newsletters, you can opt-out any time.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default LogInModal;
